import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { format, endOfDay, isToday } from 'date-fns';
import Button from 'styles/Button';
import Waiting from './Waiting';
import Container from 'styles/Prescription/Container';
import { Grid, GridItem } from 'styles/Prescription/Grid';
import usePatient, { usePatientHasData } from 'components/Navigation/usePatient';
import { IframeModal } from 'components/IframeModal';
import { useNotificationContext } from 'components/Notification';
import { AppointmentState } from '../usePrescriptions';
import { ReactComponent as TrashcanIcon } from 'assets/icons/trashcan.svg';
import { ReactComponent as SwapIcon } from 'assets/icons/swap.svg';
import ErrorBox from 'styles/ErrorBox';
import Modal from 'styles/Modal';
import Box from 'styles/Box';
import GeneralDataForm from 'components/Settings/GeneralDataForm';
import useLocalizedPrices from '../../../hooks/useLocalizedPrices';
import { useBookingUrl } from '../../../providers/BookingUrl';

export interface AppointmentProps {
  location?: React.ReactNode;
  date: Date;
  doctor: string;
  video_url?: string;
  tan?: string;
  format: string;
  state: AppointmentState;
  modify_url: string;
  cancel_url: string;
}

export type AppointmentType =
  | {
      ready: true;
      appointmentProps?: AppointmentProps;
    }
  | {
      ready: false;
      appointmentProps?: undefined;
    };

function ETerminModal({
  onClose,
  src,
}: {
  onClose: () => void;
  firstAppointment: boolean;
  src: string;
}) {
  const { data } = usePatient();

  if (!data) {
    return <></>;
  }

  //TODO: Update logic to choose link based on appointment type
  return (
    <>
      <Helmet>
        <script
          src="https://www.eTermin.net/js/resizecustomersitescroll.min.js"
          type="text/javascript"
        />
      </Helmet>
      <IframeModal
        iframeProps={{
          className: 'bg-white',
          id: 'etifr',
          src: src,
          width: '100%',
          height: '1000px',
          title: 'etermin',
        }}
        onClose={onClose}
      />
    </>
  );
}

/**
 * Button to book appointment using eTermin
 * @param appointmentUrl string of params unique to each appointment
 * @param buttonText text of button
 * @param analyticsEvent event for Google Tag Data Layers
 */
export function BookAppointmentButton({
  appointmentUrl,
  buttonText = 'Termin Buchen',
  analyticsEvent,
}: {
  appointmentUrl: string;
  buttonText?: string;
  analyticsEvent: {
    event: string;
    eventStatus: string;
  };
}) {
  const [showModal, setShowModal] = useState(false);
  const { setBookingUrl } = useBookingUrl();
  const { isLoading, hasData } = usePatientHasData();
  const navigate = useNavigate();

  const onClick = () => {
    if (appointmentUrl) {
      setBookingUrl(appointmentUrl);
    }
    if (hasData) {
      window.dataLayer.push(analyticsEvent);
      navigate(`/termin`);
    } else {
      setShowModal(true);
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Button className="w-full" onClick={onClick}>
        {buttonText}
      </Button>
      {showModal && !hasData && (
        <Modal onClose={() => setShowModal(false)}>
          <Box className="p-6 max-w-lg">
            <h2 className="mb-1">Kontaktdaten und Aufklärungsbogen</h2>
            <div className="mb-8">
              <span className="text-swopa-primary-dark-blue">
                Um einen Termin buchen zu können, möchten wir Sie bitten, Ihre noch fehlenden
                Kontaktdaten zu ergänzen.
              </span>
            </div>

            <GeneralDataForm
              buttonLabel="Speichern und Termin buchen"
              appointmentUrl={appointmentUrl}
              isBookingAppointment
            />
            <div
              onClick={() => setShowModal(false)}
              className="text-center cursor-pointer text-swopa-primary-dark-blue mt-2"
            >
              Abbrechen
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

/**
 * Component to display appointment Data
 * @param ready boolean to determine whether appointment has been confirmed or declined
 * @returns
 */
export default function Appointment({ ready, appointmentProps }: AppointmentType) {
  const { showNotification } = useNotificationContext();
  const { setBookingUrl } = useBookingUrl();
  const localizedPrices = useLocalizedPrices();
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /*const [showRequestDeleteModal, setShowRequestDeleteModal] = useState(false);*/

  if (!ready) {
    return (
      <Waiting text="Nach erfolgreicher Vorprüfung haben Sie hier die Möglichkeit einen Termin zu buchen." />
    );
  }

  if (!appointmentProps) {
    return <></>;
  }

  const CanPatientJoinAppointmentToday: boolean = useMemo(
    () => isToday(new Date(appointmentProps.date)),
    [appointmentProps.date],
  );

  if (appointmentProps.state === 'not_attended') {
    return (
      <div className="my-4 md:my-6">
        <ErrorBox>
          <span>
            <b className="font-semibold">Termin verpasst! </b>
            Leider müssen wir Ihnen aufgrund der versäumten Sprechstunde eine Ausfallrechnung in
            Höhe von {localizedPrices.no_show_fee} ausstellen.
          </span>
        </ErrorBox>
      </div>
    );
  }
  return (
    <>
      <Container>
        <span className="uppercase text-swopa-primary-dark-blue font-radikal">Sprechstunde</span>
        <div className="mt-4 xl:-mt-8 flex flex-col xl:flex-row-reverse xl:items-end xl:justify-between xl:space-x-10 xl:space-x-reverse">
          <div className="xl:grow">
            <Grid>
              <GridItem title="Datum">{format(appointmentProps.date, 'dd.MM.yyyy')}</GridItem>
              <GridItem title="Uhrzeit">{format(appointmentProps.date, 'HH:mm')}</GridItem>
              <GridItem
                title="Praxis"
                info={
                  appointmentProps.format === 'video_consultation'
                    ? 'Hinweis: Verwenden Sie die angegebene TAN um den Videoanruf zu starten.'
                    : undefined
                }
              >
                {appointmentProps.location}
              </GridItem>
              <GridItem title="Arzt/Ärztin">{appointmentProps.doctor}</GridItem>
            </Grid>
          </div>
          <div className="mt-5 xl:mt-0 xl:w-[260px] shrink-0">
            {appointmentProps.format === 'video_consultation' ? (
              <>
                {endOfDay(appointmentProps.date) > new Date() && (
                  <div className="flex flex-col justify-center">
                    {CanPatientJoinAppointmentToday ? (
                      <span className="text-center text-swopa-primary-dark-blue mb-2">
                        Ihre TAN:{' '}
                        <span
                          className="select-all cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(appointmentProps.tan + '');
                            showNotification(
                              {
                                type: 'success',
                                text: 'Ihre Tan wurde erfolgreich kopiert',
                              },
                              1000,
                            );
                          }}
                        >
                          {appointmentProps.tan === '' ? (
                            'Bald verfügbar'
                          ) : (
                            <>{appointmentProps.tan}</>
                          )}
                        </span>
                      </span>
                    ) : (
                      <span className="text-center text-swopa-primary-dark-blue mb-2">
                        Sie können keinen Videoanruf starten. Der Termin ist nicht heute.
                      </span>
                    )}
                    <a
                      title={
                        CanPatientJoinAppointmentToday
                          ? ''
                          : 'Sie können keinen Videoanruf starten. Der Termin ist nicht heute.'
                      }
                      href={appointmentProps.video_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        disabled={appointmentProps.tan === '' || !CanPatientJoinAppointmentToday}
                      >
                        Videoanruf Starten
                      </Button>
                    </a>
                  </div>
                )}
              </>
            ) : (
              <div className="uppercase border border-swopa-primary-dark-blue rounded-[4px] w-full px-10 py-3 text-swopa-primary-dark-blue text-center">
                <span>Vor Ort</span>
              </div>
            )}
          </div>
        </div>
      </Container>
      {appointmentProps.state === 'created' && (
        <div className="flex justify-center items-center mb-4 md:mb-6 h-4">
          <div
            onClick={() => setShowDeleteModal(true)}
            className="flex items-center space-x-1 text-swopa-secondary-grey hover:text-swopa-accent-green cursor-pointer"
          >
            <TrashcanIcon className="w-3 h-3" />
            <span className="text-h3 ">Termin absagen</span>
          </div>
          <div className="bg-swopa-border h-full w-[1px] mx-4" />
          <div className="flex items-center space-x-1 text-swopa-secondary-grey hover:text-swopa-accent-green cursor-pointer">
            <SwapIcon className="w-3 h-3" />
            <Link
              to={{ pathname: '/termin' }}
              className="text-xs"
              onClick={() => {
                setBookingUrl(appointmentProps.modify_url);
              }}
            >
              Termin verschieben
            </Link>
          </div>
          {showModifyModal && (
            <ETerminModal
              src={appointmentProps.modify_url}
              firstAppointment={false}
              onClose={() => setShowModifyModal(false)}
            />
          )}
          {showDeleteModal && (
            <ETerminModal
              src={appointmentProps.cancel_url}
              firstAppointment={false}
              onClose={() => setShowDeleteModal(false)}
            />
          )}
          {/*
          //ENABLE WHEN BILLING IS POSSIBLE
          
          showRequestDeleteModal && (
            <DeleteModal
              confirmDeleteLabel="Termin absagen"
              title="Stornierungsgebühr"
              text={
                <>
                  Sie sind dabei, Ihren Termin in <b>weniger als 24 Stunden</b> vor der geplanten
                  Behandlung zu stornieren. Sollten Sie Ihren Termin tatsächlich stornieren, müssen
                  wir Ihnen leider eine Gebühr in Höhe von <b>35 Euro</b> in Rechnung stellen.
                </>
              }
              onClose={() => setShowRequestDeleteModal(false)}
              onDelete={() => {
                setShowDeleteModal(true);
                setShowRequestDeleteModal(false);
              }}
            />
            )*/}
        </div>
      )}
    </>
  );
}
