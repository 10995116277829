import { ServicePageLocalization } from './types';

const servicePage: ServicePageLocalization = {
  telephone_contact: {
    DE: 'Wir sind <b>Montag bis Freitag</b> von <b>9-17 Uhr</b> und <b>Samstag</b> von<b>9-12 Uhr</b> telefonisch für Sie erreichbar: <b>+49 030 308 073 30</b>',
    CH: 'Wir sind <b>Montag bis Freitag</b> von <b>8:30-17:00 Uhr</b> telefonisch für Sie erreichbar: <b>+41 61 588 00 20</b>',
  },
  contact_email: {
    DE: 'service@help.enmedify.com',
    CH: 'support@enmedify.ch',
  },
  address: {
    DE: 'SWISS ALPINOPHARMA GmbH<br />Mindspace Office 1031<br />Kurfürstendamm 207-208<br />10719, Berlin<br />Deutschland',
    CH: 'Topmed AG<br />Steinenberg 23<br />4051 Basel<br />Schweiz',
  },
  telephone_country_code: {
    DE: '+49',
    CH: '+41',
  },
};

export default servicePage;
